'use client'
import React from 'react'

export const H3Icon: React.FC = () => (
  <svg
    aria-hidden="true"
    className="icon"
    fill="none"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.139 13.5V7.074H5.696V9.648H8.576V7.074H10.142V13.5H8.576V10.836H5.696V13.5H4.139ZM16.1146 11.745C16.1146 12.744 15.2236 13.608 13.6126 13.608C12.0736 13.608 11.0926 12.762 10.9846 11.547H12.4696C12.5146 12.114 13.0006 12.456 13.6126 12.456C14.2876 12.456 14.6746 12.132 14.6746 11.619C14.6746 11.061 14.2426 10.836 13.6216 10.836H12.9826V9.738H13.6036C14.1526 9.738 14.5486 9.486 14.5486 8.937C14.5486 8.46 14.2156 8.127 13.6486 8.127C13.0366 8.127 12.6586 8.514 12.6226 9.045H11.1916C11.2726 7.929 12.1276 6.966 13.6666 6.966C15.1876 6.966 15.9706 7.848 15.9706 8.865C15.9706 9.603 15.5026 10.143 14.8186 10.269C15.6196 10.404 16.1146 10.971 16.1146 11.745Z"
      fill="currentColor"
    />
  </svg>
)
