'use client'
import React from 'react'

export const H6Icon: React.FC = () => (
  <svg
    aria-hidden="true"
    className="icon"
    fill="none"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.639 13.5V7.074H5.196V9.648H8.076V7.074H9.642V13.5H8.076V10.836H5.196V13.5H3.639ZM13.3646 8.127C12.5456 8.127 12.0416 8.937 12.0416 9.999C12.3296 9.54 12.8246 9.207 13.5536 9.207C14.8586 9.207 15.8036 10.134 15.8036 11.376C15.8036 12.645 14.8226 13.608 13.3196 13.608C11.7266 13.608 10.6196 12.393 10.6196 10.395C10.6196 8.316 11.7716 6.966 13.4186 6.966C14.7056 6.966 15.5786 7.749 15.7316 8.829H14.3186C14.2016 8.415 13.9226 8.127 13.3646 8.127ZM13.3106 12.51C13.9586 12.51 14.3816 12.042 14.3816 11.385C14.3816 10.737 13.9586 10.278 13.3106 10.278C12.6536 10.278 12.2126 10.737 12.2126 11.385C12.2126 12.042 12.6536 12.51 13.3106 12.51Z"
      fill="currentColor"
    />
  </svg>
)
