'use client'
import React from 'react'

export const SubscriptIcon: React.FC = () => (
  <svg
    aria-hidden="true"
    className="icon"
    fill="currentColor"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.167 15L7.45002 11.36L4.73302 15H2.91302L6.55302 10.177L3.23802 5.718H5.20102L7.54102 8.89L9.89402 5.718H11.714L8.43802 10.06L12.13 15H10.167ZM16.7768 13.258C16.7768 14.155 16.1398 14.532 15.2038 15C14.5538 15.325 14.2808 15.546 14.2418 15.78H16.7898V16.82H12.7208V16.339C12.7208 15.286 13.5918 14.675 14.3588 14.233C15.0868 13.83 15.4378 13.635 15.4378 13.232C15.4378 12.894 15.2038 12.686 14.8268 12.686C14.3848 12.686 14.1248 13.024 14.1118 13.427H12.7468C12.8248 12.426 13.5528 11.633 14.8398 11.633C15.9448 11.633 16.7768 12.257 16.7768 13.258Z"
      fill="currentColor"
    />
  </svg>
)
