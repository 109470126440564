'use client'
import React from 'react'

export const H5Icon: React.FC = () => (
  <svg
    aria-hidden="true"
    className="icon"
    fill="none"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.639 13.5V7.074H5.196V9.648H8.076V7.074H9.642V13.5H8.076V10.836H5.196V13.5H3.639ZM13.1576 10.269C12.6896 10.269 12.3746 10.494 12.2216 10.737H10.8176L11.1956 7.074H15.2546V8.28H12.3206L12.1856 9.549C12.4016 9.351 12.8516 9.126 13.4636 9.126C14.7866 9.126 15.6596 10.053 15.6596 11.358C15.6596 12.609 14.7326 13.608 13.1756 13.608C11.5826 13.608 10.6556 12.753 10.5566 11.511H12.1136C12.1586 12.06 12.5456 12.465 13.1576 12.465C13.8236 12.465 14.1746 11.97 14.1746 11.376C14.1746 10.764 13.8416 10.269 13.1576 10.269Z"
      fill="currentColor"
    />
  </svg>
)
