'use client'
import React from 'react'

export const InlineBlocksIcon: React.FC = () => (
  <svg
    aria-hidden="true"
    className="icon"
    fill="none"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.33333 6.5C5.11232 6.5 4.90036 6.5878 4.74408 6.74408C4.5878 6.90036 4.5 7.11232 4.5 7.33333V12.1667C4.5 12.3877 4.5878 12.5996 4.74408 12.7559C4.90036 12.9122 5.11232 13 5.33333 13H14.6667C14.8877 13 15.0996 12.9122 15.2559 12.7559C15.4122 12.5996 15.5 12.3877 15.5 12.1667V11.6667C15.5 11.3905 15.7239 11.1667 16 11.1667C16.2761 11.1667 16.5 11.3905 16.5 11.6667V12.1667C16.5 12.6529 16.3068 13.1192 15.963 13.463C15.6192 13.8068 15.1529 14 14.6667 14H5.33333C4.8471 14 4.38079 13.8068 4.03697 13.463C3.69315 13.1192 3.5 12.6529 3.5 12.1667V7.33333C3.5 6.8471 3.69315 6.38079 4.03697 6.03697C4.38079 5.69315 4.8471 5.5 5.33333 5.5H10.3333C10.6095 5.5 10.8333 5.72386 10.8333 6C10.8333 6.27614 10.6095 6.5 10.3333 6.5H5.33333ZM13 6.5C12.7239 6.5 12.5 6.27614 12.5 6C12.5 5.72386 12.7239 5.5 13 5.5H16C16.2761 5.5 16.5 5.72386 16.5 6V9C16.5 9.27614 16.2761 9.5 16 9.5C15.7239 9.5 15.5 9.27614 15.5 9V7.20711L13.3536 9.35355C13.1583 9.54882 12.8417 9.54882 12.6464 9.35355C12.4512 9.15829 12.4512 8.84171 12.6464 8.64645L14.7929 6.5H13ZM6.16699 8.33325C6.16699 8.05711 6.39085 7.83325 6.66699 7.83325H11.0003C11.2765 7.83325 11.5003 8.05711 11.5003 8.33325C11.5003 8.60939 11.2765 8.83325 11.0003 8.83325H6.66699C6.39085 8.83325 6.16699 8.60939 6.16699 8.33325ZM6.16699 10.9999C6.16699 10.7238 6.39085 10.4999 6.66699 10.4999H13.3337C13.6098 10.4999 13.8337 10.7238 13.8337 10.9999C13.8337 11.2761 13.6098 11.4999 13.3337 11.4999H6.66699C6.39085 11.4999 6.16699 11.2761 6.16699 10.9999Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
